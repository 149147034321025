var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('DataViewWrapper', {
    attrs: {
      "filters": _vm.filters,
      "table-columns": _vm.tableColumns,
      "model": _vm.model
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }