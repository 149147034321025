<template>
  <div class="animated fadeIn">
    <DataViewWrapper
      :filters="filters"
      :table-columns="tableColumns"
      :model="model"
    />
  </div>
</template>

<script>
import models from "@/models";

export default {
  name: "PickLists",
  components: {},
  data: function() {
    return {
      model: models.warehouse.pickLists,
      filters: ["Warehouse", "Picklist Date", "PO Date"],
      tableColumns: [
        "ID",
        "Picklist Date",
        "PO Number",
        "PO Date",
        "Account Name",
        "Warehouse",
        "Status",
        "Invoice Number",
        "Created By"
      ]
    };
  },
  computed: {},
  created() {},

  mounted() {},
  methods: {},
  watch: {}
};
</script>

<style></style>
